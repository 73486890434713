import styled, { css } from 'styled-components';

interface Props {
  maxWidth?: number;
}

export default styled.div`
  margin: 0 auto;
  position: relative;
  padding: 0
    ${props =>
      css`
        ${props.theme.padding}px
      `};

  max-width: ${props =>
    css`
      ${props.theme.maxWidth}px
    `};

  @media (max-width: 800px) {
    padding: 0 20px;
  }
`;
