import styled, { css } from 'styled-components';

export const CONTENT_WIDTH = 63.676;
export const SIDEBAR_WIDTH = 100 - CONTENT_WIDTH;

export const Wrap = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-end;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Sidebar = styled.div`
  width: ${SIDEBAR_WIDTH}%;
  min-width: ${SIDEBAR_WIDTH}%;
  padding-right: 40px;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 100%;
    min-width: 100%;
    padding-right: 0;
  }
`;

export const Content = styled.div`
  width: ${CONTENT_WIDTH}%;
  min-width: ${CONTENT_WIDTH}%;

  @media (max-width: 600px) {
    width: 100%;
    min-width: 100%;
  }
`;
