import React from 'react';
import styled, { css } from 'styled-components';
import { useNavigateDispatch } from '@components/navigate';

const A = styled.a`
  display: inline-block;

  ${({ noHover }) =>
    noHover &&
    css`
      &:after {
        display: none;
      }
    `};
`;

export default ({ url, children, noHover, disabled, ...rest }) => {
  const dispatch = useNavigateDispatch();
  const handleClick = e => {
    e.preventDefault();
    dispatch({ type: 'inactive', url });
  };

  if (disabled) return children;

  if (typeof window !== 'undefined' && window.location.pathname === url) {
    return <span className="active-anchor">{children}</span>;
  }

  return (
    <A href={url} onClick={handleClick} noHover={noHover} {...rest}>
      {children}
    </A>
  );
};
