import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`
  @font-face{
    font-family: 'Nera';
    src: url('../fonts/tt-forma-nuova-neretta.woff2') format('woff2'),
         url('../fonts/tt-forma-nuova-neretta.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face{
    font-family: 'Nera';
    src: url('../fonts/tt-forma-nuova-nera.woff2') format('woff2'),
         url('../fonts/tt-forma-nuova-nera.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    -ms-overflow-x: hidden;
    height: 100%;
    min-height: 100%;
  }

  html *,
  html *:before,
  html *:after{
    box-sizing: inherit;
  }

  body {
    font-family: 'Nera', 'San Francisco', 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    font-weight: 300;
    color: #1f1d1d;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100%;
    font-weight: 300;
    position: relative;
    background: ${props =>
      css`
        ${props.theme.background}
      `};
    color: ${props =>
      css`
        ${props.theme.white}
      `};
  }

  html,
  body,
  * {
    overflow-anchor: none;
  }

  img,
  picture,
  svg,
  video {
    max-width: 100%;
  }

  #___gatsby {
    position: relative;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  ::selection {
    background: #0076CE;
    color: #DAE3E4;
  }

  strong,
  b {
    font-weight: 700;
  }

  #gatsby-noscript {
    display: none;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }

  #___gatsby:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    height: 100%
    background: black;
    transition: opacity 0.5s;
    opacity: 0;
    pointer-events: none;
  }

  body.overlay #___gatsby:after {
    opacity: 0.5;
    background: black;
    pointer-events: all;
  }

  body.no-scroll {
    overflow: hidden;
    height: 0;

    #___gatsby {
      overflow-y: scroll;
    }
  }

  .active-anchor,
  a {
    position: relative;
    display: inline-block;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      left: 0;
      bottom: -1px;
      background: #658091;
      transition: transform .2s ease;
      transform: scaleX(0);
      transform-origin: 100% 50%;
    }
  }

  .active-anchor:after {
    transition: none;
    transform: scaleX(1);
  }

  a {
    &:hover {
      text-decoration: none;
    }

    &:hover:after {
      transform: scaleX(1);
      transform-origin: 0 50%;
    }
  }

  #___gatsby,
  #___gatsby > [role="group"] {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
  }

`;
