import clone from 'clone-deep';

export interface AboutMe {
  title: string;
  portrait: string;
  bio: string[];
}

export const aboutMeSelector = (data): AboutMe => {
  const about = clone(data).allContentfulAboutMe.edges[0].node;
  about.portrait = about.portrait.file.url;
  about.bio = about.childContentfulAboutMeBioRichTextNode.json;
  about.recentPartners = about.childContentfulAboutMeRecentPartnersTextNode.internal.content;
  return about;
};

export interface Link {
  name: string;
  url: string;
}
export type Links = Link[];

export const linksSelector = (data): Links => {
  return clone(data).allContentfulAboutMeLinks.edges.map(edge => edge.node);
};

export type Service = string;
export type Services = Service[];

export const servicesSelector = (data): Services => {
  return clone(data).allContentfulServices.edges.map(edge => edge.node.name);
};
