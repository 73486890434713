import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Container from '@components/container';
import { NavContent, NavWrap } from '@components/nav';

let THRESHOLD = 400;
let lastScrollY = 0;

export default ({ about }) => {
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const scroll = () => {
      setInView(window.scrollY < lastScrollY && window.scrollY >= THRESHOLD);
      lastScrollY = window.scrollY;
    };

    window.addEventListener('scroll', scroll);
    return () => window.removeEventListener('scroll', scroll);
  }, []);

  return (
    <NavWrapSticky inView={inView}>
      <Container>
        <NavContent about={about} />
      </Container>
    </NavWrapSticky>
  );
};

export const NavWrapSticky = styled(NavWrap)`
  position: fixed;
  background: rgba(15, 22, 28, 0.9);
  pointer-events: none;
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  transition: opacity 300ms, transform 300ms;

  ${({ inView }) =>
    inView &&
    css`
      pointer-events: all;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    `}

  @media (max-width: 600px) {
    height: 70px;
  }
`;
