import styled, { css } from 'styled-components';

export const White = styled.span`
  ${props =>
    css`
      color: ${props.theme.white};
    `};
`;

export const Grey = styled.span`
  ${props =>
    css`
      color: ${props.theme.grey};
    `};
`;

export const Bold = styled.span`
  font-weight: 700;
`;

interface SpacerProps {
  size: number;
  md?: number;
  sm?: number;
}

export const Spacer = styled.div<SpacerProps>`
  display: block;
  ${({ size }) =>
    css`
      height: ${size * 10}px;
    `};

  @media (max-width: 1000px) {
    ${({ md }) =>
      md != null &&
      css`
        height: ${md * 10}px;
      `}
  }

  @media (max-width: 600px) {
    ${({ sm }) =>
      sm != null &&
      css`
        height: ${sm * 10}px;
      `}
  }
`;

interface RelativeProps {
  zIndex: number | null;
}

export const Relative = styled.div`
  position: relative;
  width: 100%;

  ${(props: SpacerProps) =>
    css`
      z-index: ${props.zIndex};
    `};
`;

export const Absolute = styled.div`
  position: absolute;
  width: 100%;
  max-width: 100%;
`;

export const Visible = styled.div`
  display: none;

  ${({ lg }) =>
    lg &&
    css`
      display: block;
    `}

  @media (max-width: 1000px) {
    ${({ md }) =>
      md
        ? css`
            display: block;
          `
        : css`
            display: none;
          `}
  }

  @media (max-width: 600px) {
    ${({ sm }) =>
      sm
        ? css`
            display: block;
          `
        : css`
            display: none;
          `}
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -2em;
  margin-left: -2em;

  @media (max-width: 800px) {
    margin-right: -20px;
    margin-left: -20px;
  }
`;

export const Col = styled.div`
  flex-basis: 100%;
  max-width: 100%;
  display: block;
  padding-right: 2em;
  padding-left: 2em;

  @media (max-width: 800px) {
    padding-right: 20px;
    padding-left: 20px;
  }

  ${({ lg }) =>
    lg &&
    css`
      flex-basis: ${(lg / 12) * 100}%;
      max-width: ${(lg / 12) * 100}%;
    `}

  @media (max-width: 1000px) {
    ${({ md }) =>
      md &&
      css`
        flex-basis: ${(md / 12) * 100}%;
        max-width: ${(md / 12) * 100}%;
      `}
  }

  @media (max-width: 600px) {
    ${({ sm }) =>
      sm &&
      css`
        flex-basis: ${(sm / 12) * 100}%;
        max-width: ${(sm / 12) * 100}%;
      `}
  }
`;
