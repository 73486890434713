import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Wrap, Sidebar, Content } from '@components/content';
import { Spacer, Visible, White } from '@components/utils';
import Container from '@components/container';
import CopyLink from '@components/copy-link';

// https://stackoverflow.com/questions/8888491/how-do-you-display-javascript-datetime-in-12-hour-am-pm-format
function formatDate(date: Date) {
  let hours = date.getHours();
  let minutes: string | number = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  // hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const TRANSFORM_SPEED = 50;

export default ({ about }) => {
  const [time, setTime] = useState('&nbsp;');
  const [style, setStyle] = useState({});
  const ref = useRef();

  const calculateNewYorkTime = () => {
    const now = new Date();
    const utc = now.getTime() + now.getTimezoneOffset() * 60000;
    const NYOffset = -4;
    const NYTime = new Date(utc + 3600000 * NYOffset);
    setTime(formatDate(NYTime));
  };

  useEffect(() => {
    const interval = setInterval(calculateNewYorkTime, 1000);
    calculateNewYorkTime();

    const scroll = () => {
      if (!ref.current) return;
      const { clientHeight } = ref.current;
      if (window.scrollY < clientHeight / 2) {
        return setStyle({ opacity: 1, transform: `translate3d(0, 0, 0)` });
      }
      if (window.scrollY > clientHeight) {
        return setStyle({ opacity: 0, transform: `translate3d(0, -${TRANSFORM_SPEED}px, 0)` });
      }
      const scrollPercentage = (window.scrollY - clientHeight / 2) / (clientHeight / 2);
      const opacity = 1 - scrollPercentage;
      const translate = scrollPercentage * TRANSFORM_SPEED;
      setStyle({ opacity, transform: `translate3d(0, -${translate}px, 0` });
    };

    window.addEventListener('scroll', scroll);
    return () => {
      clearInterval(interval);
      window.removeEventListener('scroll', scroll);
    };
  }, []);

  return (
    <HeroWrap ref={ref} style={style}>
      <HeroInner>
        <Spacer size={12} md={8.4} sm={5.5} />
        <Container>
          <Wrap>
            <Sidebar>
              <Spacer size={2} md={0.5} sm={0} />
              {time} — Brooklyn
              <Visible lg md>
                <Spacer size={2} md={2.5} />
                <div>
                  <CopyLink link={about.email} isEmail />
                </div>
                <Spacer size={0.3} />
                <div>{about.phone}</div>
              </Visible>
            </Sidebar>
            <Content>
              <Spacer sm={8.6} />
              <h1>{about.headline}</h1>
              <Visible sm>
                <Spacer size={6.5} />
                <div>
                  <CopyLink link={about.email} isEmail />
                </div>
                <Spacer size={0.3} />
                <div>{about.phone}</div>
              </Visible>
            </Content>
          </Wrap>
        </Container>
        <Spacer size={4.5} md={1} sm={0} />
      </HeroInner>
    </HeroWrap>
  );
};

export const HeroWrap = styled.header`
  background: #17222b;
  transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
`;

export const HeroInner = styled.div`
  padding: 80px 0;

  h1 {
    font-size: 60px;
    margin: -20px 0 0;
    line-height: 1.2;

    @media (max-width: 1000px) {
      font-size: 44px;
    }

    @media (max-width: 600px) {
      font-size: 38px;
    }
  }

  h2 {
    font-size: 16px;
    margin: 0;
  }

  @media (max-width: 600px) {
    padding: 70px 0;
  }
`;
