import React, { useState } from 'react';
import styled from 'styled-components';
import Sensor from 'react-visibility-sensor';
import { useSpring, animated } from 'react-spring';
import { Spacer } from '@components/utils';
import { useNavigateState } from '@components/navigate';
import Link from '@components/link';

export default ({ project, preferExpandedHero, lazyLoad, setVisible, visible, disableLink }) => {
  const src = preferExpandedHero ? project.expandedHero || project.hero : project.hero;
  const { active } = useNavigateState();

  const visibleAnimation = useSpring({
    from: {
      opacity: 0,
      translate: 50,
    },
    to: {
      opacity: visible ? 1 : 0,
      translate: visible ? 0 : 50,
    },
    duration: 500,
  });

  const initialAnimation = useSpring({
    from: {
      opacity: 0,
      translate: -10,
    },
    to: {
      opacity: active ? 1 : 0,
      translate: active ? 0 : -10,
    },
    duration: 300,
  });

  const { translate: visibleTranslate, ...restVisibleAnimation } = visibleAnimation;
  const { translate: initialTranslate, ...restInitialAnimation } = initialAnimation;
  const onChange = visible => {
    if (visible) setVisible(true);
  };

  return (
    <PortfolioHero>
      {lazyLoad ? (
        <>
          <Sensor onChange={onChange} scrollCheck={true}>
            <div style={{ position: 'absolute', height: '150px', width: '100%' }} />
          </Sensor>
          <Sensor onChange={onChange} scrollCheck={true}>
            <div style={{ position: 'absolute', height: '150px', width: '100%', bottom: '0' }} />
          </Sensor>
          <animated.div
            style={{ ...restInitialAnimation, transform: initialTranslate.interpolate(x => `translate3d(0,${x}px,0)`) }}
          >
            <Link url={`/${project.slug}/`} noHover disabled={disableLink}>
              <animated.img
                src={src}
                width="100%"
                alt="poster"
                style={{
                  ...restVisibleAnimation,
                  transform: visibleTranslate.interpolate(x => `translate3d(0,${x}px,0)`),
                }}
              />
            </Link>
          </animated.div>
        </>
      ) : (
        <Link url={`/${project.slug}/`} noHover disabled={disableLink}>
          <img src={src} width="100%" alt="poster" className={`poster poster-${project.index}`} />
        </Link>
      )}
      {project.expandedHero ? <img src={project.expandedHero} style={{ display: 'none' }} /> : null}
    </PortfolioHero>
  );
};

export const PortfolioHero = styled.div`
  position: relative;
  font-size: 0;

  a {
    width: 100%;
  }
`;
