import React, { ReactNode, useEffect } from 'react';
import Helmet from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '@components/global-styles';
import { NavigateProvider } from '@components/navigate';

const theme = {
  padding: 60,
  maxWidth: 1170 + 120,
  background: '#0F161C',
  white: '#DAE3E4',
  grey: '#658091',
  black: '#0F161C',

  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 4, // rem
    outerMargin: 4, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 46, // rem
      md: 62.5, // rem
      lg: 76, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 62.5, // em
      lg: 75, // em
    },
  },
};

interface Props {
  children: ReactNode;
}

export default ({ children, title, description, keywords, shareImage }: Props) => {
  return (
    <ThemeProvider theme={theme}>
      <NavigateProvider>
        <GlobalStyles />
        <Helmet title={title}>
          <html lang="en" />
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta name="google-site-verification" content="" />
          {shareImage ? <meta property="og:image" content={shareImage} /> : null}
        </Helmet>
        {children}
      </NavigateProvider>
    </ThemeProvider>
  );
};
