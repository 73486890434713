import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { navigate } from 'gatsby';

const NavigateStateContext = createContext({});
const NavigateDispatchContext = createContext(null);
let firstPageLoad = true;

function navigateReducer(state, action) {
  switch (action.type) {
    case 'active': {
      return {
        ...state,
        active: true,
      };
    }
    case 'inactive': {
      if (action.url) {
        setTimeout(() => navigate(action.url), 500);
      }
      return {
        ...state,
        active: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function NavigateProvider({ children }) {
  const [state, dispatch] = useReducer(navigateReducer, { active: false });
  useEffect(() => {
    setTimeout(
      () => {
        firstPageLoad = false;
        dispatch({ type: 'active' });
      },
      firstPageLoad ? 300 : 0
    );
  }, []);

  return (
    <NavigateStateContext.Provider value={state}>
      <NavigateDispatchContext.Provider value={dispatch}>{children}</NavigateDispatchContext.Provider>
    </NavigateStateContext.Provider>
  );
}

function useNavigateState() {
  return useContext(NavigateStateContext);
}

function useNavigateDispatch() {
  return useContext(NavigateDispatchContext);
}

export { NavigateProvider, useNavigateState, useNavigateDispatch };
