import React from 'react';
import styled, { css } from 'styled-components';
import { CopyLinkButton } from '@components/copy-link';
import Container from '@components/container';
import Link from '@components/link';
import CopyLink from '@components/copy-link';

interface Props {}

export default ({ links, about }: Props) => {
  return (
    <Footer>
      <Container>
        <strong>© {new Date().getFullYear()} Clarke Harris</strong>
        <ul className="nav">
          <li>
            <Link url="/">Work</Link>
          </li>
          <li>
            <Link url="/about/">About</Link>
          </li>
          <li>
            <CopyLink link={about.email} isEmail>
              Email
            </CopyLink>
          </li>
        </ul>
        <ul className="social">
          {links.map((link, index) => (
            <li key={index}>
              <a href={link.url}>{link.name}</a>
            </li>
          ))}
        </ul>
      </Container>
    </Footer>
  );
};

const Footer = styled.footer`
  background: #9fb1ba;
  padding: 90px 0;
  overflow: hidden;

  ${({ theme }) =>
    css`
      color: ${theme.black};

      a:after {
        background: ${theme.black};
      }
    `};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    float: right;
  }

  .nav {
    float: none;
    display: inline-block;
    margin-left: 25px;
  }

  li {
    padding: 0;
    margin: 0;
    display: inline-block;
  }

  li + li {
    margin-left: 25px;
  }

  @media (max-width: 1000px) {
    padding: 80px 0;
  }

  @media (max-width: 700px) {
    text-align: center;

    .social {
      display: block;
      float: none;
      margin: 20px auto 0;
    }
  }

  @media (max-width: 600px) {
    padding: 75px 0;

    ul {
      float: none;
      width: 100%;
      margin-top: 20px;
    }

    .nav {
      margin-left: 0;
    }
  }

  ${CopyLinkButton} {
    background: ${({ theme }) => theme.background};
    color: white;
  }
`;
