import slugify from '@sindresorhus/slugify';
import clone from 'clone-deep';

const MultipleImagesSelector = detail => {
  return {
    images: detail.images.map(image => image.file.url),
  };
};

const SingleImageSelector = detail => {
  return {
    width: detail.width,
    caption: detail.caption,
    image: detail.image.file.url,
  };
};

const QuoteSelector = detail => {
  return {
    quote: detail.quote.quote,
  };
};

const BreakdownSelector = detail => {
  return {
    title: detail.title,
    body: detail.body && detail.body.body,
    width: detail.width,
    align: detail.align === true ? 'left' : 'right',
    image: detail.image.file.url,
    poster: detail.videoThumbnail && detail.videoThumbnail.file && detail.videoThumbnail.file.url,
  };
};

const VideoSelector = detail => {
  return {
    width: detail.width,
    caption: detail.caption,
    video: detail.video.file.url,
    poster: detail.videoThumbnail && detail.videoThumbnail.file && detail.videoThumbnail.file.url,
  };
};

const detailsSelector = details => {
  if (!details) return [];

  return details.map(detail => {
    const type = detail.__typename.replace('Contentful', '');
    let data;

    if (type === 'MultipleImages') {
      data = MultipleImagesSelector(detail);
    }

    if (type === 'SingleImage') {
      data = SingleImageSelector(detail);
    }

    if (type === 'Quote') {
      data = QuoteSelector(detail);
    }

    if (type === 'Breakdown') {
      data = BreakdownSelector(detail);
    }

    if (type === 'Video') {
      data = VideoSelector(detail);
    }

    return {
      type,
      data,
    };
  });
};

export const projectSelector = data => {
  return clone(data)
    .allContentfulProject.edges.map(({ node }) => {
      return {
        position: node.position == null ? 10000 : node.position,
        title: node.title,
        slug: slugify(node.title),
        type: node.type,
        darkNavigation: node.darkNavigation,
        description: node.description && node.description.description,
        metadata: node.metadata && node.metadata.metadata.split(/\n/),
        credits: node.credits && node.credits.credits.split(/\n/).map(item => item.split(':')),
        hero: node.hero.fixed.src,
        expandedHero: node.expandedHero && node.expandedHero.fixed && node.expandedHero.fixed.src,
        details: detailsSelector(node.details),
      };
    })
    .filter(project => project.title !== 'Sample Project')
    .sort((a, b) => a.position - b.position)
    .map((project, index) => {
      project.index = index;
      return project;
    });
};
