import React from 'react';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useNavigateState } from '@components/navigate';

export default function FadeTransition({ children, appearDelay, flex }) {
  const { active } = useNavigateState();
  const animation = useSpring({
    from: {
      opacity: 0,
    },
    to: {
      opacity: active ? 1 : 0,
    },
    delay: active ? appearDelay || 0 : 0,
    duration: 500,
  });

  return (
    <Wrap flex={flex} style={animation}>
      {children}
    </Wrap>
  );
}

const Wrap = styled(animated.div)`
  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      flex-flow: column;
      flex: 1;
    `}
`;
