import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import copy from 'copy-to-clipboard';

export default ({ link, children, isEmail }) => {
  const [click, setClick] = useState(false);
  const href = isEmail ? `mailto:${link}` : link;

  const onClick = e => {
    e.preventDefault();
    copy(link);
    setClick(true);
    setTimeout(() => setClick(false), 1000);
  };

  return (
    <CopyLink>
      <a href={href} onClick={onClick}>
        {children || link}
      </a>
      <CopyLinkButton visible={click}>Copied</CopyLinkButton>
    </CopyLink>
  );
};

export const CopyLink = styled.span`
  position: relative;

  &:hover:after {
    opacity: 1;
  }
`;

export const CopyLinkButton = styled.span`
  background: #dae3e4;
  border-radius: 4px;
  padding: 3px 25px;
  font-size: 16px;
  color: #17222b;
  letter-spacing: 0;
  position: absolute;
  right: -120px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: opacity 0.5s;
  opacity: 0;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
    `}
`;
