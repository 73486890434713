import React from 'react';
import styled, { css } from 'styled-components';
import { Grey, Bold } from '@components/utils';
import Container from '@components/container';
import Link from '@components/link';
import CopyLink from '@components/copy-link';
import { CopyLinkButton } from '@components/copy-link';
import { useNavigateState } from '@components/navigate';

export const NavContent = ({ about }) => {
  return (
    <ul>
      <li>
        <Bold>{about.subHeadline}</Bold>
      </li>
      <li>
        <Grey>
          <Link url="/">Work</Link>
        </Grey>
      </li>
      <li>
        <Grey>
          <Link url="/about/">About</Link>
        </Grey>
      </li>
      <li>
        <Grey>
          <CopyLink link={about.email} isEmail>
            Email
          </CopyLink>
        </Grey>
      </li>
    </ul>
  );
};

export default ({ about, dark }) => {
  const { active } = useNavigateState();

  return (
    <NavWrap role="navigation" aria-label="Main" dark={dark} active={active}>
      <Container>
        <NavContent about={about} />
      </Container>
    </NavWrap>
  );
};

export const NavWrap = styled.div`
  position: absolute;
  height: 100px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  flex-flow: column;
  opacity: 0;
  transition: opacity 0.3s;

  ${({ active }) =>
    active &&
    css`
      opacity: 1;
    `}

  > * {
    width: 100%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  li {
    margin: 0 auto;
    padding: 0;
    display: inline-block;
  }

  li:first-of-type {
    margin-left: 0;
  }

  li:last-of-type {
    margin-right: 0;
  }

  ${({ dark }) =>
    dark &&
    css`
      color: black;

      ${Grey} {
        color: #0f161c;
      }

      a:after {
        background: #0f161c;
      }
    `}

  @media (max-width: 1470px) {
    ${CopyLinkButton} {
      top: 55px;
      right: 0;
    }
  }
`;
