import clone from 'clone-deep';

export const metaSelector = data => {
  const meta = clone(data).allContentfulMeta.edges[0].node;

  meta.description = meta.description && meta.description.description;
  meta.keywords = meta.keywords && meta.keywords.keywords;
  meta.shareImage = meta.shareImage && meta.shareImage.fixed.src;
  return meta;
};
